import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'common/src/components/Image';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Input from 'common/src/components/Input';
import FeatureBlock from 'common/src/components/FeatureBlock';
import ParticlesComponent from '../particles';
import Container from 'common/src/components/UI/Container';
import { Icon } from 'react-icons-kit';
import { ic_arrow_forward } from 'react-icons-kit/md/ic_arrow_forward';
import { BannerSquareShape, BannerCircleShape } from '../app.style';
import touchImage from 'common/src/assets/image/app/kiosk.jpg';
import { useIntl } from "gatsby-plugin-intl";
import BannerImage from 'common/src/assets/image/app/banner-image.png';
import { ic_play_circle_filled } from 'react-icons-kit/md/ic_play_circle_filled';
import { play } from 'react-icons-kit/entypo/play';
import { openModal, closeModal } from '@redq/reuse-modal';

import {
  DiscountWrapper,
  DiscountLabel,
  ButtonWrapper,
  EmailInputWrapper,
  VideoModal,
  PlayButton,
  VideoWrapper,
} from './banner.style';

const CloseModalButton = () => (
  <Button
    className="modalCloseBtn"
    variant="fab"
    onClick={() => closeModal()}
    icon={<i className="flaticon-plus-symbol" />}
  />
);
const ModalContent = () => (
  <VideoWrapper>
    <iframe
      src="https://www.youtube.com/embed/2T7n6gg_siI"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen>
    </iframe>
  </VideoWrapper>
);
const DomainSection = ({
  SectionWrapper,
  row,
  col,
  title,
  description,
  button,
  image,
  imageArea,
  btnStyleTwo,
  discountAmount,
  discountText,
  lang,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      appScreenshot: file(relativePath: { eq: "image/app/touchlesser.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const [myValue, setMyValue] = useState(null);
  const intl = useIntl();

  const handleVideoModal = () => {
    openModal({
      config: {
        className: 'video-modal',
        disableDragging: true,
        width: '100%',
        height: '100%',
      },
      component: ModalContent,
      componentProps: {},
      closeComponent: CloseModalButton,
      closeOnClickOutside: true,
    });
  };
  return (
    <Box {...SectionWrapper}>
      
      <BannerSquareShape />
      <Container>
        <Box {...row}>
          <Box {...col}>
           
            <FeatureBlock
              title={<Heading content={intl.formatMessage({ id: "banner_title" })} {...title} />}
              description={<Text content={intl.formatMessage({ id: "banner_description" })} {...description} />}
            />
            <ButtonWrapper>
              <VideoModal style={{width:"auto", minWidth:354, maxWidth:400,height:120, marginTop:50}} onClick={handleVideoModal}>
                <PlayButton tabIndex="0">
                  <Icon icon={play} size={40} />
                </PlayButton>
                <label style={{cursor:"pointer"}}>{intl.formatMessage({ id: "watch_video" })}</label>
              </VideoModal>
            </ButtonWrapper>
          </Box>
          <Box {...col} {...imageArea}>
            <Box {...image} style={{textAlign:'center'}}>
              <img
                style={{width:"45%", height:"70%"}}
                src={touchImage}
                alt="Touchlessr App"
              />
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

DomainSection.propTypes = {
  SectionWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  btnStyleTwo: PropTypes.object,
  discountAmount: PropTypes.object,
  discountText: PropTypes.object,
  textArea: PropTypes.object,
  lang: PropTypes.string,
};

DomainSection.defaultProps = {
  lang:"en",
  SectionWrapper: {
    as: 'section',
    pt: '80px',
    pb: '80px',
    overflow: 'hidden',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  imageAreaRow: {
    flexDirection: 'row-reverse',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '100%', '100%', '44%', '44%'],
    mt: '-40px',
  },
  imageArea: {
    width: ['0%', '0%', '0%', '45%', '40%'],
    ml: 'auto',
  },
  title: {
    fontSize: ['26px', '30px', '30px', '48px', '60px'],
    fontWeight: '300',
    color: '#0f2137',
    letterSpacing: '-0.01px',
    mb: '20px',
  },
  description: {
    fontSize: '16px',
    color: '#343d48',
    lineHeight: '33px',
    mb: '10px',
  },
  button: {
    type: 'button',
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#fff',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    mt: '22px',
    colors: 'primaryWithBg',
  },
  image: {
    ml: 'auto',
    mt: '70px',
  },
  btnStyleTwo: {
    minWidth: '156px',
    ml: '15px',
    bg: '#fff',
    color: 'rgb(26, 115, 232)',
  },
  textArea: {
    width: ['100%', '100%', '50%', '55%', '55%'],
  },
  discountAmount: {
    content: 'update',
    fontSize: '14px',
    fontWeight: '600',
    color: '#fff',
    mb: 0,
    as: 'span',
    mr: '0.4em',
    bg: 'rgb(26, 115, 232)',
  },
  discountText: {
    content: 'Version 2.5.0 has just released .',
    fontSize: '13px',
    fontWeight: '400',
    color: '#0f2137',
    mb: 0,
    as: 'span',
    ml: '10px',
  },
};

export default DomainSection;
