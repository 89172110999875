import React, { useState, useEffect, Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { Modal } from '@redq/reuse-modal';
import { appTheme } from 'common/src/theme/app';
import {
  GlobalStyle,
  AppWrapper,
  ConditionWrapper,
} from '../containers/App/app.style';
import { ResetCSS } from 'common/src/assets/css/style';
import Navbar from '../containers/App/Navbar';
import DomainSection from '../containers/App/Banner';
import Footer from '../containers/App/Footer';
import HowItWorks from '../containers/App/HowItWorks';
import FeatureSlider from '../containers/App/FeatureSlider';
import FeatureSliderTwo from '../containers/App/FeatureSliderTwo';
import PricingSection from '../containers/App/PricingSection';
import FaqSection from '../containers/App/FaqSection';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import '@redq/reuse-modal/es/index.css';
import SEO from '../components/seo';
import seoImage from 'common/src/assets/image/app/seo_image.png';
import { useIntl } from "gatsby-plugin-intl";

function getSize() {
  return {
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    outerHeight: window.outerHeight,
    outerWidth: window.outerWidth,
  };
}


function useWindowSize() {
  let [windowSize, setWindowSize] = useState(getSize());

  function handleResize() {
    setWindowSize(getSize());
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
}
const keyWords = [
  'tablet',
  'smartphone',
  'kiosks',
  'remote control',
  'touchless kiosks',
  'touchless interfaces',
  'gesture control',
  'touchless information systems and kiosks',
  'iOS touchless information systems and kiosks',
  'android touchless information systems and kiosks',
  'touchless Passenger Check-In Kiosks',
  'Passenger Check-In Kiosks',
  'Check-In Kiosks',
  'web app',
  'android',
  'ios',
  'crossplatform',
];
export default () => {
  const size = process.browser && useWindowSize();
  const intl = useIntl();
  const innerWidth = process.browser && size.innerWidth;

  return (
    <ThemeProvider theme={appTheme}>
      <Fragment>
        <SEO title="Touchlessr" image={seoImage} keywords={keyWords} />
        <Modal />
        <ResetCSS />
        <GlobalStyle />
        <AppWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar lang={intl.locale} />
            </DrawerProvider>
          </Sticky>
          <DomainSection />
          <HowItWorks lang={intl.locale}/>
          <ConditionWrapper id="keyfeature">
            {innerWidth > 1100 ? <FeatureSlider /> : <FeatureSliderTwo />}
          </ConditionWrapper>
          <PricingSection lang={intl.locale} />
          <FaqSection lang={intl.locale} />
          <Footer lang={intl.locale} />
        </AppWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
