import React from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import 'react-image-gallery/styles/css/image-gallery.css';
import Container from 'common/src/components/UI/Container';
import FeatureSliderWrapper from './featureSlider.style';
import Image1 from 'common/src/assets/image/app/slide-1.svg';
import Image2 from 'common/src/assets/image/app/slide-2.svg';
import Image3 from 'common/src/assets/image/app/slide-3.svg';
import Image4 from 'common/src/assets/image/app/slide-4.svg';
import Image5 from 'common/src/assets/image/app/slide-5.svg';
import Image6 from 'common/src/assets/image/app/slide-6.svg';
import Thumb1 from 'common/src/assets/image/app/interconnectivity.svg';
import Thumb2 from 'common/src/assets/image/app/remote.svg';
import Thumb3 from 'common/src/assets/image/app/keyboard.svg';
import Thumb4 from 'common/src/assets/image/app/touch.svg';
import Thumb5 from 'common/src/assets/image/app/crossplatform.svg';
import Thumb6 from 'common/src/assets/image/app/safe.svg';
import { useIntl } from "gatsby-plugin-intl";

// import DomainSection from '../container/Hosting/Domain';

const FeatureSlider = ({ sectionSubTitle, sectionTitle, sectionHeader }) => {
  const intl = useIntl();
  const images = [
    {
      original: `${Image1}`,
      thumbnail: `${Thumb1}`,
      thumbnailLabel: `${intl.formatMessage({ id: "featureSlider_Interconnectivity_label" })}`,
      originalAlt: `${intl.formatMessage({ id: "featureSlider_Interconnectivity_alt" })}`,
      thumbnailAlt: `${intl.formatMessage({ id: "featureSlider_Interconnectivity_alt" })}`,
    },
    {
      original: `${Image2}`,
      thumbnail: `${Thumb2}`,
      thumbnailLabel: `${intl.formatMessage({ id: "featureSlider_RemoteControl_label" })}`,
      originalAlt: `${intl.formatMessage({ id: "featureSlider_RemoteControl_alt" })}`,
      thumbnailAlt: `${intl.formatMessage({ id: "featureSlider_RemoteControl_alt" })}`,
    },
    {
      original: `${Image3}`,
      thumbnail: `${Thumb3}`,
      thumbnailLabel: `${intl.formatMessage({ id: "featureSlider_Keyboard_label" })}`,
      originalAlt: `${intl.formatMessage({ id: "featureSlider_Keyboard_alt" })}`,
      thumbnailAlt: `${intl.formatMessage({ id: "featureSlider_Keyboard_alt" })}`,
    },
    {
      original: `${Image4}`,
      thumbnail: `${Thumb4}`,
      thumbnailLabel: `${intl.formatMessage({ id: "featureSlider_Touchpad_label" })}`,
      originalAlt: `${intl.formatMessage({ id: "featureSlider_Touchpad_alt" })}`,
      thumbnailAlt: `${intl.formatMessage({ id: "featureSlider_Touchpad_alt" })}`,
    },
    {
      original: `${Image5}`,
      thumbnail: `${Thumb5}`,
      thumbnailLabel: `${intl.formatMessage({ id: "featureSlider_Crossplatform_label" })}`,
      originalAlt: `${intl.formatMessage({ id: "featureSlider_Crossplatform_alt" })}`,
      thumbnailAlt: `${intl.formatMessage({ id: "featureSlider_Crossplatform_alt" })}`,
    },
    {
      original: `${Image6}`,
      thumbnail: `${Thumb6}`,
      thumbnailLabel: `${intl.formatMessage({ id: "featureSlider_Secure_label" })}`,
      originalAlt: `${intl.formatMessage({ id: "featureSlider_Secure_alt" })}`,
      thumbnailAlt: `${intl.formatMessage({ id: "featureSlider_Secure_alt" })}`,
    },
  ];

  return (
    <FeatureSliderWrapper>
      <div className="FeatureSliderInner">
        <span> </span>
        <span> </span>
        <span> </span>
      </div>
      <Container>
        <Box {...sectionHeader}>
          <Text 
            content={intl.formatMessage({ id: "featureSlider_whyChoose" })}
            {...sectionSubTitle} />
          <Heading
            content={intl.formatMessage({ id: "featureSlider_KeyFeatures" })}  
            {...sectionTitle} 
          />
        </Box>
        <Box className="FeatureSlider">
          <ImageGallery
            items={images}
            className="Slider-img"
            showPlayButton={false}
            showFullscreenButton={false}
            showNav={false}
            showBullets={true}
            autoPlay={true}
          />
        </Box>
      </Container>
    </FeatureSliderWrapper>
  );
};

// FeatureSlider style props
FeatureSlider.propTypes = {
  sectionSubTitle: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionHeader: PropTypes.object,
};

// FeatureSlider default style
FeatureSlider.defaultProps = {
  sectionHeader: {},
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.13em',
    fontWeight: '700',
    color: '#0582CA',
    mb: '10px',
  },
  // section title default style
  sectionTitle: {
    textAlign: 'center',
    fontSize: ['20px', '24px', '24px', '24px', '30px'],
    fontWeight: '400',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
  },
};

export default FeatureSlider;
